<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-coa-group-modal" title="COA Group Name" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-8 col-sm-8 col-lg-8">
              <label for="name" class="col-form-label col-form-label-sm">COA Group Name <span class="custom-required">*</span></label>
              <input type="text" v-model="coa_group.name" v-validate="'required|max:255'" id="name" name="COA group name" class="form-control form-control-sm" placeholder="Enter COA group name"/>
              <div class="invalid-feedback">{{ errors.first('COA group name') }}</div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="code" class="col-form-label col-form-label-sm">COA Group Code <span class="custom-required">*</span></label>
              <input type="text" v-model="coa_group.code" v-validate="{ required: true, min: 2, max: 2, regex: /^[1-9][0-9]*$/ }" id="code" name="COA group code" class="form-control form-control-sm" placeholder="Enter COA group code"/>
              <div class="invalid-feedback">{{ errors.first('COA group code') }}</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="addCoaGroupLoader"  @click.prevent="save">Add</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-coa-group-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addUpdateDisputeType',
  props: ['value'],
  data() {
    return {
      coa_group: {
        name: '',
        code: '',
      },
      validation_errors: {},
      show: false,
      addCoaGroupLoader: false,
    }
  },
  mounted() {},
  methods: {
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addCoaGroupLoader = true
          apiClient.post('api/expense/coa-group', this.coa_group).then(response => {
            this.addCoaGroupLoader = false
            this.coa_group = {}
            const coaCreate = response.data.coa_create
            this.$emit('input', this.value.concat({ id: coaCreate.id, name: coaCreate.name, code: coaCreate.code }))
            this.$bvModal.hide('add-update-coa-group-modal')
          }).catch(error => {
            this.addCoaGroupLoader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
