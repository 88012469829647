<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <form action="" role="form">
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="coa_group_id" class="col-form-label col-form-label-sm">COA Group Name <span
                  class="custom-required">*</span></label>
                <div class="input-group input-group-sm">
                  <select v-model="expense.coa_group_id" v-validate="'required'" @change="getCoaSubGroup1($event.target.value)" id="coa_group_id" name="COA group name" class="form-control form-control-sm">
                    <option selected disabled :value="''">Select COA Group Name</option>
                    <option v-for="(coa_group, index) in coa_groups" :key="index" :value="coa_group.id">{{ coa_group.name }} - {{ coa_group.code }}</option>
                  </select>
                  <div class="ml-4 input-group-append">
                    <span class="input-group-text btn-success" id="basic-addon2">
                      <a href="javascript: void(0);" v-b-modal.add-update-coa-group-modal @click="$bvModal.show('add-update-coa-group-modal'), resetForm()"><i class="fa fa-plus"/> Add COA Group</a>
                    </span>
                  </div>
                  <div class="invalid-feedback"> {{ errors.first('COA group name') }}</div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="coa_sub_group1_id" class="col-form-label col-form-label-sm">COA Sub Group 1 Name</label>
                <div class="input-group input-group-sm">
                  <select v-model="expense.coa_sub_group1_id"  @change="getCoaSubGroup2($event.target.value)"  id="coa_sub_group1_id" name="COA sub group name 1" class="form-control form-control-sm">
                    <option selected disabled :value="''">Select COA Sub Group Name</option>
                    <option v-for="(coa_sub_group1, index) in filteredCoaSubGroup1s" :key="index" :value="coa_sub_group1.id">{{ coa_sub_group1.name }} - {{ coa_sub_group1.code }}</option>
                  </select>
                  <div class="ml-4 input-group-append" v-if="expense.coa_group_id">
                    <span class="input-group-text btn-success" id="basic-addon3">
                      <a href="javascript: void(0);" v-b-modal.add-update-coa-sub-group1-modal @click="$bvModal.show('add-update-coa-sub-group1-modal'), resetForm()"><i class="fa fa-plus"/> Add COA Sub Group 1</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="coa_sub_group2_id" class="col-form-label col-form-label-sm">COA Sub Group 2 Name</label>
                <div class="input-group input-group-sm">
                  <select v-model="expense.coa_sub_group2_id" id="coa_sub_group2_id" name="COA sub group name 2" class="form-control form-control-sm">
                    <option selected disabled :value="''">Select COA Sub Group 2 Name</option>
                    <option v-for="(coa_sub_group2, index) in filteredCoaSubGroup2s" :key="index" :value="coa_sub_group2.id">{{ coa_sub_group2.name }} - {{ coa_sub_group2.code }}</option>
                  </select>
                  <div class="ml-4 input-group-append" v-if="expense.coa_sub_group1_id && expense.coa_group_id">
                    <span class="input-group-text btn-success" id="basic-addon4">
                      <a href="javascript: void(0);" v-b-modal.add-update-coa-sub-group2-modal @click="$bvModal.show('add-update-coa-sub-group2-modal'), resetForm()"><i class="fa fa-plus"/> Add COA Sub Group 2</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="name" class="col-form-label col-form-label-sm">COA Name <span class="custom-required">*</span></label>
                <input type="text" v-model="expense.name" v-validate="{ required: true }" id="name" name="COA name" class="form-control form-control-sm" placeholder="Enter COA Name"/>
                <div class="invalid-feedback">{{ errors.first('COA name') }}</div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 col-sm-6 col-lg-6">
                <label for="acc_code" class="col-form-label col-form-label-sm">COA ACC Code <span class="custom-required">*</span></label>
                <input type="text" v-model="acc_code" disabled v-validate="{ required: true }" id="acc_code" name="COA acc code" class="form-control form-control-sm" placeholder="Enter COA ACC Code"/>
                <div class="invalid-feedback">{{ errors.first('COA acc code') }}</div>
              </div>
            </div>
            <a-button class="btn btn-success waves-effect waves-light mt-3" :loading="saveExpense" @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Create COA Expense</a-button>
          </form>
        </div>
      </div>
    </div>
    <coa-group-name v-model="coa_groups"></coa-group-name>
    <coa-sub-group1-name v-model="filteredCoaSubGroup1s" :coa_groups="coa_groups" :coa_group_id="expense.coa_group_id"></coa-sub-group1-name>
    <coa-sub-group2-name v-model="filteredCoaSubGroup2s" :coa_sub_group1s="filteredCoaSubGroup1s" :coa_group_id="expense.coa_group_id" :coa_sub_group1_id="expense.coa_sub_group1_id"></coa-sub-group2-name>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import coaGroupName from '@/views/expense/coa/coaMaster/modal/coaGroupName'
import coaSubGroup1Name from '@/views/expense/coa/coaMaster/modal/coaSubGroup1Name'
import coaSubGroup2Name from '@/views/expense/coa/coaMaster/modal/coaSubGroup2Name'

export default {
  name: 'create',
  components: {
    coaGroupName,
    coaSubGroup1Name,
    coaSubGroup2Name,
  },
  data() {
    return {
      coa_groups: [],
      coa_sub_group1s: [],
      coa_sub_group2s: [],
      filteredCoaSubGroup1s: [],
      filteredCoaSubGroup2s: [],
      expense: {
        name: '',
        coa_group_id: '',
        coa_sub_group1_id: '',
        coa_sub_group2_id: '',
      },
      validation_errors: {},
      saveExpense: false,
      show: false,
    }
  },
  mounted() {
    this.getCode()
  },
  computed: {
    acc_code: function() {
      const coaGroupId = this.expense.coa_group_id
      const coaSubGroup1Id = this.expense.coa_sub_group1_id
      const coaSubGroup2Id = this.expense.coa_sub_group2_id
      let coaGroupCode, coaSubGroup1Code, coaSubGroup2Code
      if (coaGroupId) {
        const coaGroup = this.coa_groups.filter(obj => {
          return obj.id === coaGroupId
        })
        coaGroupCode = coaGroup[0].code
      }
      if (coaSubGroup1Id) {
        const coaSubGroup1 = this.filteredCoaSubGroup1s.filter(obj => {
          return obj.id === coaSubGroup1Id
        })
        coaSubGroup1Code = coaSubGroup1[0].code
      } else {
        coaSubGroup1Code = '00'
      }
      if (coaSubGroup2Id) {
        const coaSubGroup2 = this.filteredCoaSubGroup2s.filter(obj => {
          return obj.id === coaSubGroup2Id
        })
        coaSubGroup2Code = coaSubGroup2[0].code
      } else {
        coaSubGroup2Code = '00'
      }
      if (coaGroupId) {
        return ((coaGroupCode || '') + (coaSubGroup1Code || '') + (coaSubGroup2Code || '')) || ''
      }
      return ''
    },
  },
  methods: {
    getCoaSubGroup1(val) {
      const coaGroupId = parseInt(val)
      this.filteredCoaSubGroup1s = []
      this.filteredCoaSubGroup2s = []
      this.expense.coa_sub_group1_id = ''
      this.expense.coa_sub_group2_id = ''
      this.filteredCoaSubGroup1s = this.coa_sub_group1s.filter(item => item.coa_group_id === coaGroupId)
    },
    getCoaSubGroup2(val) {
      const coaSubGroup1Id = parseInt(val)
      this.filteredCoaSubGroup2s = []
      this.filteredCoaSubGroup2s = this.coa_sub_group2s.filter(item => item.coa_sub_group1_id === coaSubGroup1Id)
    },
    getCode() {
      apiClient.get('api/expense/codes')
        .then(response => {
          const data = response.data
          this.coa_groups = data.coa_groups
          this.coa_sub_group1s = data.coa_sub_group1s
          this.coa_sub_group2s = data.coa_sub_group2s
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.saveExpense = true
          this.expense.acc_code = this.acc_code
          apiClient.post('api/expenses', this.expense)
            .then(response => {
              this.saveExpense = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'expenseList' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.saveExpense = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      // this.coa_groups = []
    },
  },
}
</script>

<style scoped>
.input-group-text {
  background-color: #ffffff !important;
}
</style>
