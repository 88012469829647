<template>
  <div>
    <div class="mb-5" v-if="coa_sub_group1_id && coa_group_id">
      <b-modal id="add-update-coa-sub-group2-modal" title="COA Sub Group 2" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <h6>COA Sub Group 1 Name: {{ coaSubGroup1Name(coa_sub_group1_id, coa_group_id) ? coaSubGroup1Name(coa_sub_group1_id, coa_group_id)[0].name : '' }} </h6>
          <h6>COA Sub Group 1 Code: {{ coaSubGroup1Name(coa_sub_group1_id, coa_group_id) ? coaSubGroup1Name(coa_sub_group1_id, coa_group_id)[0].code : '' }} </h6>
          <div class="form-row">
            <div class="col-md-8 col-sm-8 col-lg-8">
              <label for="name" class="col-form-label col-form-label-sm">COA Sub Group 2 Name <span class="custom-required">*</span></label>
              <input type="text" v-model="coa_sub_group2.name" v-validate="'required'" id="name" name="COA group name" class="form-control form-control-sm" placeholder="Enter COA sub group 2 name"/>
              <div class="invalid-feedback">{{ errors.first('COA group name') }}</div>
            </div>
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="code" class="col-form-label col-form-label-sm">COA Sub Group 2 Code <span class="custom-required">*</span></label>
              <input type="text" v-model="coa_sub_group2.code" v-validate="'required|integer|min:2|max:2'" id="code" name="COA group code" class="form-control form-control-sm" placeholder="Enter COA sub group 2 code"/>
              <div class="invalid-feedback">{{ errors.first('COA group code') }}</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="addCoaSubGroup2Loader"  @click.prevent="save">Add</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-coa-sub-group2-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addUpdateDisputeType',
  props: ['value', 'coa_sub_group1s', 'coa_group_id', 'coa_sub_group1_id'],
  data() {
    return {
      coa_sub_group2: {
        name: '',
        code: '',
      },
      validation_errors: {},
      show: false,
      addCoaSubGroup2Loader: false,
    }
  },
  mounted() {},
  methods: {
    coaSubGroup1Name (coaSubGroup1Id, coaGroupId) {
      if (coaSubGroup1Id) {
        return this.coa_sub_group1s.filter(function(elem) {
          if (elem.id === coaSubGroup1Id) return elem
        })
      }
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.coa_sub_group2.coa_sub_group1_id = this.coa_sub_group1_id
          this.addCoaSubGroup2Loader = true
          apiClient.post('api/expense/coa-sub-group2', this.coa_sub_group2).then(response => {
            this.addCoaSubGroup2Loader = false
            this.coa_sub_group2 = {}
            const coaSubGroup2Create = response.data.coa_sub_group2_create
            this.$emit('input', this.value.concat({ id: coaSubGroup2Create.id, name: coaSubGroup2Create.name, code: coaSubGroup2Create.code }))
            this.$bvModal.hide('add-update-coa-sub-group2-modal')
          }).catch(error => {
            this.addCoaSubGroup2Loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
